
export const status = 'AFFILIATE_STATUS';

const initialState = {
    affiliateStatus: '',

}

const affiliateStatusReducer = (state = initialState, action) => {

    switch(action.type){
        case status:
            return {
                ...state,
                affiliateStatus: action.value ,
            
            };

        default:
            return state;
    }
}

export default affiliateStatusReducer
