
export const IS_COMPLETE = 'IS_COMPLETE';

const initialState = {
    isComplete: false,
    type: 0
}

const profileReducer = (state = initialState, action) => {

    switch(action.type){
        case IS_COMPLETE:
            return {
                ...state,
                isComplete: action.value ,
                type: action.value.type ? action.value.type:0
            };

        default:
            return state;
    }
}

export default profileReducer
