
export const IS_SPINNER = 'IS_SPINNER';

const initialState = {
    isSpinner: false,
    type: 0
}

const userReducer = (state = initialState, action) => {
    switch(action.type){
        case IS_SPINNER:
            return {
                ...state,
                isSpinner: action.value.isSpin ? action.value.isSpin : action.value ,
                type: action.value.type ? action.value.type:0
            };

        default:
            return state;
    }
}

export default userReducer
